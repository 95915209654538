<template>
    <section class="developments my-5">
        <h2 class="developments__title mt-3 mb-5">Каталог разработок</h2>

        <div
            class="developments__about-block "
            :style="`background-image: url(${mainCategory.image})`"
        >
            <div class="gradient-shadow"></div>
            <div class="about-block h-100 w-100 d-flex flex-column flex-md-row align-items-md-end p-4 mb-5">
                <div class="col-12 col-md-7">
                    <div class="about-block__title text-wrap" :title="mainCategory.title">{{ mainCategory.title }}</div>
                    <div class="about-block__description mt-3">
                        {{ mainCategory.short_description }}
                    </div>
                </div>

                <Button
                    variant="outline-light"
                    class="ms-md-auto mt-4 mt-md-0"
                    @click="goToDevelopmentPage(mainCategory.id)"
                >
                    <template #default>
                        Подробнее о разработке
                    </template>

                    <template #icon>
                        <img src="../../assets/components/header/arrow-right.svg" alt="" class="button-icon">
                    </template>
                </Button>
            </div>
        </div>

        <div class="mt-5">
            <div class="developments__search-input search-input__input-group input-group mb-3">

                <form class="col-12 d-flex" @submit.prevent="searchProducts">
                    <div class="input-group-text">
                        <img src="../../assets/views/developments/search.svg" alt="">
                    </div>
                    <input
                        v-model="searchedText"
                        type="text"
                        class="search-input__input form-control"
                        aria-label="Text input with checkbox"
                        placeholder="Поиск по разработкам"
                    >
                    <button
                        class="btn btn-link text-decoration-none input-group-text text-muted"
                        style="font-size: 1.25rem"
                        v-show="searchedText"
                        type="reset"
                        @click="clearSearch"
                        aria-label="Очистить форму поиска"
                        title="Очистить форму поиска"
                    >
                        &times;
                        <span class="visually-hidden">Очистить форму поиска</span>
                    </button>
                </form>
            </div>
        </div>

        <div class="d-flex flex-column flex-md-row">
            <div class="d-flex align-items-center flex-wrap">
                <Badge
                    v-for="(productCategory, categoryIndex) in productCategories"
                    :key="categoryIndex"
                    variant="custom"
                    class="me-2 mt-1"
                    :style="`--developments-btn-custom-bg-color: ${productCategory.colour}; --developments-btn-custom-marker-color: ${productCategory.marker_colour}`"
                    @click="getProductsByCategory(productCategory.id)"
                >
                    <template #default>
                        <span>{{ productCategory.title }}</span>
                    </template>
                </Badge>
            </div>

            <div class="d-flex justify-content-center ms-md-auto">
                <div class="dropdown">
                    <Button
                        variant="outline-dark"
                        class="mt-4 mt-md-0 text-nowrap"
                        data-bs-toggle="dropdown" aria-expanded="false"
                    >
                        <template #default>
                            Скачать каталог
                        </template>

                        <template #icon>
                            <img src="../../assets/views/developments/arrow-down.svg" alt="" class="button-icon">
                        </template>
                    </Button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="/media/storage/ITMO_Catalogue_2021_it.pdf" target="_blank">Каталог 2021</a></li>
                        <li><a class="dropdown-item" href="/media/storage/ITMO_Catalogue_2022.pdf" target="_blank">Каталог 2022</a></li>
                        <li><a class="dropdown-item" href="https://tt.itmo.ru/media/storage/Каталог_научно_технических_разработок_2023.pdf" target="_blank">Каталог 2023</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="developments__cards-grid mt-4" v-if="products.length">
            <router-link
                class="dev-card d-flex flex-column p-3 text-decoration-none"
                v-for="product in products" :key="product.id"
                :to="{ name: 'Development', params: { id: product.id } }"
            >
                <div class="my-5 mx-auto">
                    <img :src="getProductImage(product)" alt="">
                </div>
                <div class="mt-auto">
                    <div class="dev-card__title">
                        <span>{{ product.title }}</span>
                    </div>
                    <div class="mt-2">
                        <img src="../../assets/views/developments/i-arrow-right-black.svg" alt="">
                    </div>
                </div>
            </router-link>
        </div>

        <div class="d-flex mt-5" v-if="isShowMore">
            <Button
                class="mx-auto"
                variant="outline-dark"
                @click="getMoreProducts"
            >
                <template #default>
                    Показать больше
                </template>

                <template #icon>
                    <img src="../../assets/views/developments/arrow-down.svg" alt="" class="button-icon">
                </template>
            </Button>
        </div>
    </section>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";
import Button from "@/components/common/Button/Button";
import Badge from "@/components/common/Badge/Badge";
import routerMixin from "@/mixins/routerMixin";

export default {
    name: "Developments",

    components: {
        Button,
        Badge
    },

    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const { goToPage, isMenuVisible, toggleMenu } = routerMixin();

        /* Data */
        const searchedText = ref('')
        const mainCategory = ref({})


        onBeforeRouteUpdate(async (from) => {
            const { query } = from.query;
            await store.dispatch('productCategories/getProductCategories')

            if (!query) {
              await store.dispatch('products/getProducts')
            } else {
              await store.dispatch('products/selectCategory', query)
              await store.dispatch('products/getProductsByCategoryId', query)
            }
        })

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            const { query } = route.query;

            await store.dispatch('productCategories/getProductCategories')

            if (!query) {
                await store.dispatch('products/getProducts')
            } else {
                await store.dispatch('products/selectCategory', query)
                await store.dispatch('products/getProductsByCategoryId', query)
            }

            document.body.scrollIntoView({ behavior: "smooth" });


            [mainCategory.value] = await store.dispatch('products/getRandomProducts', 1)
        })

        /* Computed */
        const productCategories = computed(() => store.getters['productCategories/productCategories'])

        const products = computed(() => store.getters['products/products'])

        const isShowMore = computed(() => store.getters['products/nextPage'])

        const selectedCategory = computed(() => store.getters['products/selectedCategory'])

        /* Methods */
        const getMoreProducts = () => {
            if (selectedCategory.value) {
                store.dispatch('products/getProductsByCategoryId', selectedCategory.value)
            } else {
                store.dispatch('products/getProducts', { searchedText: searchedText.value, showMore: true })
            }
        }

        const getProductImage = (product) => {
            const [productCategory] = productCategories.value.filter((category) => category.id === product.category.id)
            return productCategory.image
        }

        const getProductsByCategory = (category) => {
            store.dispatch('products/getProductsByCategoryId', category)
        }

        const searchProducts = () => {
            if (!searchedText.value) return

            router.replace({ query: { query: searchedText.value } })

            if (selectedCategory.value) {
                store.dispatch('products/getProductsByCategoryId', selectedCategory.value, searchedText.value)
            } else {
                store.dispatch('products/getProducts', { searchedText: searchedText.value, showMore: false })
            }
        }

        const clearSearch = () => {
            searchedText.value = ''
            router.replace({ query: { query: '' } })
            store.dispatch('products/getProducts', { searchedText: '', showMore: false })
        }

        const goToDevelopmentPage = async (productId) => {
            await router.push({ name: 'Development', params: { id: productId } } )
        }

        return {
            searchedText,
            goToPage,
            productCategories,
            products,
            isShowMore,
            mainCategory,
            goToDevelopmentPage,
            getMoreProducts,
            getProductImage,
            getProductsByCategory,
            searchProducts,
            clearSearch,
        }
    }
}
</script>
